import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout/Layout'
import { SEO } from '../helpers/SEO'
import styled from 'styled-components'
import { device } from '../components/GlobalStyles/helpers/device'
import { H1 } from '../components/atoms/typo/typo'
import { HeroImage } from '../components/HeroImage'
import { GatsbyFluidImageProps } from 'gatsby-source-sanity'
import { BlockContent } from '../components/BlockContent'

const ResourcesWrapper = styled.div`
  margin: 15px;

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 35px;
  }
`

const ResourceContainer = styled.div`
  padding: 5px;

  p {
    margin-top: 5px;
  }

  * {
    margin: 0;
    padding-left: 0;
  }
`

interface Resource {
  link?: string
  number?: string
  title: string
  date: string
  _rawDescription: React.ReactNode
  file?: {
    asset: {
      url: string
    }
  }
  id: string
  forService: [
    {
      title: string
    },
  ]
}

interface Props {
  data: {
    resources: { nodes: [Resource] }
    image: {
      childImageSharp: {
        fluid: GatsbyFluidImageProps
      }
    }
  }
}

const Resource = (resource: Resource): JSX.Element => {
  return (
    <ResourceContainer key={resource.id}>
      <a href={resource.link || resource.file?.asset.url}>
        <h2>{resource.title}</h2>
      </a>

      <h3>
        {resource.number} - {resource.date}
      </h3>

      <BlockContent body={resource._rawDescription} />
    </ResourceContainer>
  )
}

const ResourcesPage = ({ data }: Props): JSX.Element => {
  return (
    <Layout>
      <SEO
        title="Student Resources"
        description="Documents the clients of Queen City Flying Service need."
        location="Cincinnati, OH"
      />

      <HeroImage fluid={data.image.childImageSharp.fluid} alt="The old terminal at the Lunken Airport" />

      <H1>Document Library</H1>

      <ResourcesWrapper>{data.resources.nodes.map((resource) => Resource(resource))}</ResourcesWrapper>
    </Layout>
  )
}

export default ResourcesPage

export const query = graphql`
  {
    resources: allSanityResource {
      nodes {
        link
        number
        title
        date(formatString: "MM-DD-YYYY")
        _rawDescription
        file {
          asset {
            url
          }
        }
        id
        forService {
          title
        }
      }
    }
    image: file(relativePath: { eq: "foreflight.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
